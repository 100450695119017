import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
export const prependFBScript = FACEBOOK_PIXEL_BASE => `
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <script async defer src="https://connect.facebook.net/en_US/sdk.js"></script>
    <script>
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('dataProcessingOptions', ['LDU'], 0, 0);
      fbq('init', ${FACEBOOK_PIXEL_BASE});
      fbq('track', 'PageView');
    </script>
    <noscript>
      <img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_BASE}&ev=PageView&noscript=1"
      />
    </noscript>    
`;

export const prependGoogleTagScript = GOOGLE_TAG_MANAGER_CONTAINER_ID => `

<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?${GOOGLE_TAG_MANAGER_CONTAINER_ID}"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${GOOGLE_TAG_MANAGER_CONTAINER_ID}');
</script>
`;

export const addNetentScript = `
<script
    type="text/javascript"
    src="https://netent-static.casinomodule.com/gameinclusion/library/gameinclusion.js"
  ></script>
`;

export const addGoogleTagIframe = GOOGLE_TAG_MANAGER_CONTAINER_ID => `
<noscript
    ><iframe
      src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_CONTAINER_ID}"
      height="0"
      width="0"
      style="display: none; visibility: hidden"
    ></iframe
  ></noscript>
`;

export const addOneTrustCookies = ONE_TRUST_DATA_DOMAIN_SCRIPT => `<!-- OneTrust Cookies Consent Notice start -->
  <script
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    type="text/javascript"
    charset="UTF-8"
    data-domain-script="${ONE_TRUST_DATA_DOMAIN_SCRIPT}"
  ></script>
  <script type="text/javascript">
    function OptanonWrapper() {}
  </script>
  <!-- OneTrust Cookies Consent Notice end -->`;

export const handleHtmlScripts = () => {
  const facebookSDKinitScript = document
    .createRange()
    .createContextualFragment(prependFBScript(ENVIRONMENT_VARIABLES.FACEBOOK_PIXEL_BASE));
  const netentScript = document.createRange().createContextualFragment(addNetentScript);
  const googleTagScript = document
    .createRange()
    .createContextualFragment(prependGoogleTagScript(ENVIRONMENT_VARIABLES.GOOGLE_TAG_MANAGER_CONTAINER_ID));

  const googleTagIframe = document
    .createRange()
    .createContextualFragment(addGoogleTagIframe(ENVIRONMENT_VARIABLES.GOOGLE_TAG_MANAGER_CONTAINER_ID));

  const oneTrustCookies = document
    .createRange()
    .createContextualFragment(addOneTrustCookies(ENVIRONMENT_VARIABLES.ONE_TRUST_DATA_DOMAIN_SCRIPT));

  document.body.appendChild(netentScript);

  if (ENVIRONMENT_VARIABLES.GOOGLE_TAG_MANAGER_CONTAINER_ID) {
    document.body.prepend(googleTagScript);
    document.body.appendChild(googleTagIframe);
  }

  if (ENVIRONMENT_VARIABLES.ONE_TRUST_DATA_DOMAIN_SCRIPT) {
    document.body.prepend(oneTrustCookies);
  }

  document.body.prepend(facebookSDKinitScript);
};

